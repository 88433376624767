<template>
    <main id="liste">
        <HeaderTab
            v-if="swiper_tabs"
            :title="$t('acte.planning_actes')"
            swiper_cle="liste_actes"
            :swiper_tabs="swiper_tabs"
            :swiper_action="handleSwiperChange"
        />

        <div id="content">
            <shutter-panel ref="shutterPanel" />
        </div>

        <!-- Modales -->
        <ModalReplan 
            ref="modal_actes_replan"
            @ok="applyReplanActe"
        />

        <ModalDemandeAnalyse
            ref="modal_demande_analyse"
        />

        <ModalCourrierActes ref="modal_courrier_actes"/>
    </main>
</template>


<script type="text/javascript">
    import ActeMixin from "@/mixins/Actes.js"
	import ActeTypeMixin from "@/mixins/ActeType.js"
    import Navigation from "@/mixins/Navigation.js"
    import Shutter from "@/mixins/Shutter.js"
    import ShutterActs from '@/mixins/shutters-manager/Acts.js'
    import ShutterGyneco from '@/mixins/shutters-manager/Gyneco.js'
    import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: "Planning",
		mixins: [
            ActeMixin,
			ActeTypeMixin,
            Navigation,
            Shutter, 
            ShutterActs,
            ShutterGyneco
        ],
		data () {
			return {
                /* Swiper */
				swiper_tabs: [
                    {
                        label: 'acte.tous',
                        active: true,
                        name: ''
                    }
                ],
                actual_tab: {},
                
                /* Button actions events handler */
                events_tab: {
                    'TableAction::acteAjout': this.handleActesOperations,
                    'TableAction::DeleteActes': this.deleteActePostConfirmation,
                    'TableAction::ReplanActe': this.replanActesModal,
                    'TableAction::goToAnalyse': this.openModalAnalyse,
                    'TableAction::goToDupliqueActe': this.dupliqueActe,
                    'TableAction::goToPrintActs': this.openModalCourrierActes,
                    'Planning::refreshTable': this.refreshTable
                },

                /* Pour la gestion d'ouverture / fermeture des panels */
                last_operation: '',
                last_row_id: 0,

                /* Utilisé par la mixin */
				interacted_actes: [],
				actes_groupes: []
			}
        },
		mounted() {
            this.init_component()
		},
		methods: {
            /* Initialisation des composants */
            async init_component() {
                this.actes_groupes = (await this.getActesGroupes(true, true))
					.filter(groupe => groupe.actestypes.some(type => type.active))

				// const actestypes = await this.getUsefullActTypes()
    //             this.actes_groupes = this.actes_groupes.filter(ag => actestypes.findIndex(at => at.actestype_codegroupe === ag.actesgroupe_code) !== -1)

                this.actes_groupes.forEach(acte_groupe => {
                    this.swiper_tabs.push({
                        label: 'acte.'+acte_groupe.actesgroupe_code,
                        active: false,
                        name: acte_groupe.actesgroupe_label
                    })
				})

				this.shutterPanel().open(this.shutters['act-list'])
				this.shutterPanel().update('act-list', 'actesTypesGyneco', this.getGroupActesTypes(this.reproTabName))

				if(this.routeTab) {
					const swiper_tab = this.swiper_tabs.find(tab => tab.name === this.routeTab)
					if(swiper_tab) this.changeTab(swiper_tab)
				}

				if(!this.actual_tab) {
	                this.actual_tab = this.swiper_tabs[0]
				}

                this.table_busy = false
            },

			handleSwiperChange(tab) {
				const actual_route = this.$route.name
				if (this.$route.params.tab !== tab.name) {
					this.$router.replace({ name: actual_route, params: { tab: tab.name }})
				}
			},

            /* Hook changement de table */
			changeTab(tab) {
                this.actual_tab = null
				const prevTab = this.swiper_tabs.find(tab => tab.active === true)
                prevTab.active = false  
                tab.active = true
                this.actual_tab = _cloneDeep(tab)

                this.shutterPanel().update('act-list', 'actesTypes', this.getGroupActesTypes(this.actual_tab.name))
				this.shutterPanel().update('act-list', 'isReproTab', this.isReproTab)
                this.shutterPanel().update('act-list', 'isPerfsTab', this.isPerfsTab)
                this.closeAllShutters()
			},

            /* Modale de repalinication - ouverture et callback */
            replanActesModal(actes) {
				this.interacted_actes = actes
                const date = actes.length === 1 ? Date.parseTz(actes[0].actes_date) : new Date()
                this.$refs.modal_actes_replan.openModal(date, actes)
			},

			refreshTable() {
                this.shutterPanel().reload('act-list')
                // this.shutterPanel().reload('horse-gyneco')
			},

            /* Callback après validation de la suppression d'un acte */
            deleteActePostConfirmation(actes) {
                const act_ids = actes.map(act => act.actes_id)

                // Si on a la prévisualisation sur un des actes supprimés
                if(!this.lastOperationIsAboutHorse && act_ids.indexOf(this.last_row_id) > -1) {
                    this.closeAllShutters()
                }

                this.applyDeleteActes(actes).then(() => {
                    this.shutterPanel().reload('act-list')
                    // this.shutterPanel().reload('horse-gyneco')
                })
            },

            /* Ouverture des volets d'ajout d'actes */
            async handleActesOperations(params) {
                const operation = params.state || 'add'
                const row_id = params.pk_id || params.acte_id || params.horse_id

                this.closeAllShutters()

                if(operation == this.last_operation && row_id == this.last_row_id) {
                    this.last_operation = ''
                    this.last_row_id = 0

                    return
                }
                else if(operation == 'add') {
                    this.setupActAdd('', 'REPRO')
                }
                else if(operation == 'modification') {
                    this.setupActEdit(row_id, params.lot)
                }
                else if(operation == 'validation') {
                    this.setupActValidation(row_id)
                }
                else if(operation == 'horse_gyneco') {
                    const horse = await this.getHorseById(row_id)
                    this.setupHorseGyneco(horse, true)
                }

                this.last_operation = operation
                this.last_row_id = row_id
            },

            /* Close all shutters */
            closeAllShutters() {
                this.shutterPanel().keepFirst()
            },

            onShuttersSubmit() {
                this.shutterPanel().reload('act-list')
                // this.shutterPanel().reload('horse-gyneco')
                this.closeAllShutters()
            },

            openModalAnalyse(actes) {
                this.$refs.modal_demande_analyse.openModal(actes)
            },

            async dupliqueActe(acte_id) {
               const acte = await this.getActeFromId(acte_id, 'withContactAndArticles')
               this.setupActDuplication(acte)
            },

            openModalCourrierActes(acte_ids) {
                this.$refs.modal_courrier_actes.openModal(acte_ids)
            }
        },
        computed: {
			routeTab() {
				return this.$route.params.tab
			},
			reproTabName() {
				return 'REPRODUCTION'
			},
			perfsTabName() {
				return 'PERFORMANCE'
			},
            isPerfsTab() {
                return this.actual_tab.name == this.perfsTabName
            },
            isReproTab() {
                return this.actual_tab.name == this.reproTabName
            },
            shutters() {
                return {
                    'act-list': {
                        name: 'act-list',
                        title: this.$t('acte.vos_actes'),
						cmpPath: 'components/ShutterScreens/Act/PlanningAct',
                        props: {
							actesTypes: this.getGroupActesTypes(this.actual_tab.name),
							actesTypesGyneco: null,
                            isReproTab: this.isReproTab,
                            isPerfsTab: this.isPerfsTab,
                            handleActesOperations: this.handleActesOperations
                        }
                    }
                }
            },
            lastOperationIsAboutHorse() {
                return this.last_operation == 'horse_gyneco'
            }
		},
		watch: {
			routeTab(tab_name) {
				const tab = this.swiper_tabs.find(tab => tab.name == tab_name)
				if(tab) this.changeTab(tab)
			}
		},
		components: {
            ModalCourrierActes: () => import('@/components/Modals/ModalCourrierActes'),
            ModalReplan: () => import('@/components/Modals/ModalReplan'),
            ModalDemandeAnalyse: () => import('@/components/Modals/ModalDemandeAnalyse'),
            HeaderTab: () => import('@/components/HeaderTab')
        }
	}
</script>
